import React, {useCallback} from 'react';
import styles from './Checkbox.module.scss';
import {ReactComponent as IconCheck} from '../../icons/check.svg';

export function Checkbox({onChange = () => {}, children}) {
  let _onChange = useCallback(
    (e) => {
      onChange(e.target.checked);
    },
    [onChange],
  );
  return (
    <label className={styles.container}>
      <input type="checkbox" onChange={_onChange} className={styles.input} />
      <span className={styles.button}>
        <IconCheck className={styles.check} />
      </span>
      <span className={styles.title}>{children}</span>
    </label>
  );
}

import React, {useState, useCallback, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import styles from './SummaryPage.module.scss';
import {Button} from '../Button';
import {ReactComponent as IconPen} from '../../icons/pen.svg';
import {BlockInfo} from './BlockInfo';
import {storeGeneral} from '../../Store/General';
import {format as dateFormat} from '../../Helpers/date';
import {checkInstagram} from '../../Helpers/checkInstagram';

export const SummaryPage = observer(({nextTab, prevTab, editDetails}) => {
  let [showEditButton, setShowEditButton] = useState(false);

  let toggleShowEditButton = useCallback(() => {
    setShowEditButton(!showEditButton);
  }, [setShowEditButton, showEditButton]);

  let birthday = useMemo(
    () => dateFormat(storeGeneral.user.birthday),
    [storeGeneral.user.birthday],
  );

  return (
    <div className={styles.container}>
      <div className={styles.title}>Summary</div>
      <div className={styles.content}>
        <BlockInfo title={'Personal Details'}>
          {showEditButton ? (
            <button className={styles.editButton} onClick={editDetails}>
              Edit
            </button>
          ) : null}
          <IconPen
            className={styles.edit}
            fill={showEditButton ? '#EC6562' : '#000000'}
            onClick={toggleShowEditButton}
          />
          {storeGeneral.user.photo?.base64 ? (
            <img
              alt="avatar"
              src={storeGeneral.user.photo.base64}
              className={styles.avatar}
            />
          ) : null}

          <div className={styles.columnTitle}>Your details</div>

          <div className={styles.parameter}>First Name:</div>
          <div className={styles.value}>{storeGeneral.user.firstName}</div>

          <div className={styles.parameter}>Last Name:</div>
          <div className={styles.value}>{storeGeneral.user.lastName}</div>

          <div className={styles.parameter}>Birthday:</div>
          <div className={styles.value}>{birthday}</div>

          <div className={styles.parameter}>Gender:</div>
          <div className={styles.value}>{storeGeneral.user.gender}</div>

          <div className={styles.parameter}>Email:</div>
          <div className={styles.value}>{storeGeneral.user.email}</div>

          <div className={styles.parameter}>Phone:</div>
          <div className={styles.value}>{storeGeneral.user.phoneNumber}</div>

          <div className={styles.parameter}>Address:</div>
          <div className={styles.value}>
            {storeGeneral.user.street} {storeGeneral.user.addressCity}{' '}
            {storeGeneral.user.province} {storeGeneral.user.country}{' '}
            {storeGeneral.user.postalCode}
          </div>

          <div className={styles.parameter}>Emergency Contact:</div>
          <div className={styles.value}>
            {storeGeneral.user.emergencyContactName}
            <br />
            {storeGeneral.user.emergencyContactPhone}
          </div>

          <div className={styles.parameter}>Referred By:</div>
          <div className={styles.value}>{storeGeneral.user.promoCode}</div>
          <div className={styles.parameter}>Salesperson:</div>
          <div className={styles.value}>{storeGeneral.user.salesperson}</div>

          {checkInstagram() ? (
            <>
              <div className={styles.columnTitle}>Payment Method Details</div>

              <div className={styles.parameter}>Credit Card Number:</div>
              <div className={styles.value}>{storeGeneral.user.ccNumber}</div>

              <div className={styles.parameter}>Credit Card Expiry Date:</div>
              <div className={styles.value}>{storeGeneral.user.ccExpiry}</div>

              <div className={styles.parameter}>Credit Card CVV:</div>
              <div className={styles.value}>{storeGeneral.user.ccCVV}</div>
            </>
          ) : null}
        </BlockInfo>

        <BlockInfo title={'Membership Details'}>
          <div className={styles.columnTitle}>YOUR membership details</div>

          <div className={styles.parameter}>Club Location:</div>
          <div className={styles.value}>
            {storeGeneral.city === 'lv' ? 'Liberty Village' : 'Winnipeg'}
          </div>

          <div className={styles.parameter}>Membership Type:</div>
          <div className={styles.value}>
            {storeGeneral.membership.activeItem.name}
          </div>

          <div className={styles.parameter}>Initial Membership Term:</div>
          <div className={styles.value}>
            {storeGeneral.membership.activeItem.commitment}
          </div>

          <div className={styles.parameter}>Start Date:</div>
          <div className={styles.value}>
            {storeGeneral.membership.startDate}
          </div>

          <div className={styles.parameter}>Initiation Fee:</div>
          <div className={styles.value}>
            ${storeGeneral.membership.activeItem.newPrice.enrolment}
          </div>

          <div className={styles.parameter}>Road Map Session:</div>
          <div className={styles.value}>
            ${storeGeneral.membership.activeItem.newPrice.roadMapSession}
          </div>

          <div className={styles.parameter}>
            First Bi-Weekly Membership Dues
          </div>
          <div className={styles.value}>
            ${storeGeneral.membership.firstBiWeeklyDues}
          </div>

          <div className={styles.parameter}>Tax:</div>
          <div className={styles.value}>
            ${storeGeneral.membership.totalTax}
          </div>

          <div className={styles.parameter}>Bi-Weekly Dues (Before Tax):</div>
          <div className={styles.value}>
            ${storeGeneral.membership.activeItem.newPrice.total}
          </div>

          <div className={styles.parameter}>
            <span className={styles.boldText}>
              {storeGeneral.city === 'lv' ? 'Total Due Upon Start Date' : 'Total Due Today'}
            </span>
          </div>
          <div className={styles.value}>
            <span className={styles.boldText}>
              ${storeGeneral.membership.totalDueToday}
            </span>
          </div>

          <div className={styles.parameter}>
            First Regulary Scheduled Membership Dues Date (following Initial
            Pro-rated):
          </div>
          <div className={styles.value}>
            {storeGeneral.membership.nextDuesDate}
          </div>
        </BlockInfo>
      </div>
      <div className={styles.buttons}>
        <Button
          text={'Back'}
          theme={'inverse'}
          className={styles.button}
          onClick={prevTab}
        />
        <Button text={'NEXT'} className={styles.button} onClick={nextTab} />
      </div>
    </div>
  );
});

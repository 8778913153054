import React from 'react';
import {observer} from 'mobx-react-lite';
import styles from './Tabs.module.scss';
import {ReactComponent as IconArrow} from '../../icons/arrow.svg';
import {storeGeneral} from '../../Store/General';

export const TabsNavigation = observer(({children}) => {
  return (
    <div className={styles.navigation}>
      <div className={styles.progress}>
        <div
          className={styles.progressLine}
          style={{
            width:
              (100 / children.length) * (storeGeneral.navigation.current + 1) +
              '%',
          }}
        />
      </div>
      <button
        className={`${styles.btn} ${styles.btnPrev} ${
          storeGeneral.navigation.current === 0 ? styles.btnDisabled : ''
        }`}
        onClick={storeGeneral.navigation.prev}>
        <IconArrow fill="#FFFFFF" className={styles.btnIcon} />
      </button>
      {children}
      <button
        className={`${styles.btn} ${styles.btnNext} ${
          storeGeneral.navigation.current + 1 === children.length ||
          storeGeneral.navigation.current === storeGeneral.navigation.maxStep
            ? styles.btnDisabled
            : ''
        }`}
        onClick={storeGeneral.navigation.next}>
        <IconArrow fill="#FFFFFF" className={styles.btnIcon} />
      </button>
    </div>
  );
});

import {useEffect} from 'react';

export function useScripts(code) {
  useEffect(() => {
    const scriptOnBody = document.createElement('noscript');
    scriptOnBody.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${code}"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

    const scriptOnHead = document.createElement('script');
    scriptOnHead.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${code}');`;

    document.body.appendChild(scriptOnBody);
    document.head.appendChild(scriptOnHead);
    return () => {
      document.body.removeChild(scriptOnBody);
      document.head.removeChild(scriptOnHead);
    };
  }, [code]);
}

import {makeAutoObservable, reaction} from 'mobx';
import {StoreDetails} from '../Details';
import {StoreMembership} from '../Membership';
import {StorePayment} from '../Payment';
import {StoreNavigation} from '../Navigation';
import {TermsStore} from '../Terms';
import {format as dateFormat} from '../../Helpers/date';
import {API_ADDRESS} from '../../config';
import {subscribe} from '../../API/Subscribe';
import {createUser} from '../../API/CreateUser';

class StoreGeneral {
  //city = 'wpg';
  city = new URLSearchParams(window.location.search).get('club');

  membership = new StoreMembership();
  user = new StoreDetails();
  payment = new StorePayment();
  navigation = new StoreNavigation();
  terms = new TermsStore();

  constructor() {
    makeAutoObservable(this);
    reaction(() => this.maxStep, this.navigation.setMaxStep);
  }

  get maxStep() {
    let stepStatus = [
      this.membership.complete,
      this.user.complete,
      this.terms.complete,
      this.payment.complete,
    ];
    let maxStep = stepStatus.findIndex((i) => !i);
    if (maxStep === -1) {
      maxStep = stepStatus.length + 1;
    }
    return maxStep;
  }

  getMemberships = () => {
    this.membership.get(this.city);
  };

  goToDetails = () => {
    this.navigation.setCurrent(1);
  };

  createGuestProfile = async () => {
    let response = await storeGeneral.createUser({
      ...storeGeneral.user,
      city: storeGeneral.city,
      membership: storeGeneral.membership.activeItem.saveNameAs,
    });
    if (response.status) {
      this.generateAndSendPdf();
      // this.navigation.next();
    } else {
      alert('Please check that all the required fields have been filled-in!');
    }
  };

  generateAndSendPdf = async () => {
    let gym = this.city;
    let {firstName, lastName, userID} = this.user;
    // let {name: planName} = this.membership.activeItem;
    let address = this.user.street + ' ' + this.user.addressCity + ' ' + this.user.province + ' ' + this.user.postalCode;

    let data = {
      gym: gym,
      userID: userID,
      name: this.user.firstName,
      surname: this.user.lastName,
      phone: this.user.phoneNumber,
      email: this.user.email,
      address: address,
      gender: this.user.gender,
      dob: this.user.birthday,
      plan: this.membership.activeItem.name,
      //startDate: dateFormat(new Date()),
      startDate: this.membership.startDate,
      enrollment: this.membership.activeItem.newPrice.enrolment,
      session: this.membership.activeItem.newPrice.roadMapSession,
      membershipDues: this.membership.firstBiWeeklyDues,
      biWeeklyBeforeTax: this.membership.activeItem.newPrice.total,
      membershipDuesDate: this.membership.nextDuesDate,
      signature: this.user.signature,
    };

    await fetch(`${API_ADDRESS}/pdf`, {
      method: 'POST',
      headers: {
        // prettier-ignore
        "Accept": 'application/json',
        // prettier-ignore
        "Content-Type": 'application/json',
      },
      body: JSON.stringify(data),
    });

    /*
    let link = document.createElement('a');
    link.href = `${SERVER_API_ADDRESS}/pdf/${gym}/${userID}/${firstName}/${lastName}/${planName}`;
    link.setAttribute('download', 'altea-active-agreement.pdf');
    link.target = '_blank';
    link.click();
    this.navigation.next();
    */
  };

  setCityFromURL() {
    let city = new URLSearchParams(window.location.search).get('club');
    if (city) {
      this.city = city;
    }
    //this.membership.get(this.city);
  }

  async createUser(data) {
    this.user.setData(data);

    let response = await createUser(data);

    if (response.status) {
      this.user.userID =
        response.data.data['userId'] ||
        response.data.data['user_id'] ||
        response.data.data['userID'];

      //console.log(this.user.userID);
      let address = this.user.street + ' ' + this.user.addressCity + ' ' + this.user.province + ' ' + this.user.postalCode;

      let subscribeData = {
        email: data.email,
        gym: this.city,
        info: {
          name: this.user.firstName,
          surname: this.user.lastName,
          phone: this.user.phoneNumber,
          email: this.user.email,
          address: address,
          gender: this.user.gender,
          dob: this.user.birthday,
          plan: this.membership.activeItem.name,
          startDate: dateFormat(new Date()),
          enrollment: this.membership.activeItem.newPrice.enrolment,
          session: this.membership.activeItem.newPrice.roadMapSession,
          membershipDues: this.membership.firstBiWeeklyDues,
          tax: this.membership.totalTax,
          totalDueToday: this.membership.totalDueToday,
          membershipDuesDate: this.membership.nextDuesDate,
          biWeeklyBeforeTax: this.membership.activeItem.newPrice.total,
          signature: this.user.signature,
        },
      };
      subscribe(subscribeData).then((response) => {
        //console.log(response);
      });

      return {status: true};
    } else {
      return response;
    }
  }
}

export const storeGeneral = new StoreGeneral();

import React from 'react';
import {Field} from 'react-final-form';
import styles from './Input.module.scss';
import {ErrorText} from '../ErrorText';
import InputMask from 'react-input-mask';

export function InputExpiry({
  name,
  className = '',
  placeholder,
  defaultValue,
  readOnly = false,
}) {
  return (
    <Field name={name} initialValue={defaultValue} defaultValue={defaultValue}>
      {({input, meta}) => {
        return (
          <div className={`${className} ${styles.container}`}>
            <InputMask
              onFocus={input.onFocus}
              onBlur={input.onBlur}
              className={`${styles.input} ${
                meta.error && meta.touched ? styles.isError : ''
              } ${readOnly ? styles.isDisabled : ''}`}
              mask="99/99"
              onChange={!readOnly ? input.onChange : undefined}
              readOnly={readOnly}
              value={input.value}
              placeholder={placeholder}
            />
            {meta.error && meta.touched ? (
              <ErrorText error={meta.error} />
            ) : null}
          </div>
        );
      }}
    </Field>
  );
}

import React, {useCallback, useState, useRef, useEffect} from 'react';
import styles from './TermsPopup.module.scss';
import {Popup} from '../Popup';
import {ScrollBar} from '../ScrollBar';
import {Checkbox} from '../Checkbox';
import {Button} from '../Button';
import {storeGeneral} from '../../Store/General';
import SignaturePad from 'react-signature-pad-wrapper';
import {checkMobile} from '../../Helpers/checkMobile';

export function TermsPopup({city, onNext, onPrev}) {
  let [termsAccept, setTermsAccept] = useState(false);
  let [signature, setSignature] = useState(null);
  let signaturePad = useRef(null);

  let next = useCallback(() => {
    storeGeneral.terms.setData();
    storeGeneral.user.setSignature(signaturePad.current.toDataURL());
    onNext();
  }, [onNext]);

  let onEnd = useCallback(() => {
    setSignature(signaturePad.current.toDataURL());
  }, [onNext]);

  return (
    <Popup
      title="TERMS AND CONDITIONS"
      onClose={onPrev}
      alternativeAdaptive={true}>
      <div className={styles.content}>
        <ScrollBar className={styles.scroll}>
          <p
            className={styles.text}
            dangerouslySetInnerHTML={{__html: storeGeneral.terms.items[city]}}
          />
        </ScrollBar>
        <Checkbox onChange={setTermsAccept}>
          I agree with the terms and conditions
        </Checkbox>
        {/*{checkMobile() ? (*/}
        <div className={styles.signaturePad}>
          <div className={styles.signaturePadText}>
            Please sign here to confirm:
          </div>
          <div className={styles.signaturePadLine} />
          <SignaturePad
            ref={signaturePad}
            height={111}
            options={{
              minWidth: 1,
              maxWidth: 1,
              penColor: 'rgb(0, 0, 0)',
              dotSize: 2,
              backgroundColor: 'rgb(246, 246, 246)',
              onEnd: onEnd,
            }}
          />
        </div>
        {/*) : null}*/}
        <Button
          onClick={next}
          disabled={!termsAccept || !signature}
          text="Next"
          className={styles.button}
        />
      </div>
    </Popup>
  );
}

import {action, makeObservable, observable, reaction} from 'mobx';

export class StoreDetails {
  firstName = '';
  lastName = '';
  birthday = '';
  gender = '';
  street = '';
  addressCity = '';
  country = 'Canada';
  phoneNumber = '';
  email = '';
  province = '';
  postalCode = '';
  emergencyContactName = '';
  emergencyContactPhone = '';
  promoCode = '';
  salesperson = '';
  photo = {
    file: null,
    base64: null,
  };
  signature = null;

  userID = null;

  complete = false;

  constructor() {
    makeObservable(this, {
      firstName: observable,
      lastName: observable,
      birthday: observable,
      gender: observable,
      street: observable,
      addressCity: observable,
      phoneNumber: observable,
      email: observable,
      province: observable,
      postalCode: observable,
      country: observable,
      emergencyContactName: observable,
      emergencyContactPhone: observable,
      promoCode: observable,
      salesperson: observable,
      photo: observable,
      complete: observable,
      userID: observable,
      signature: observable,
      setSignature: action.bound,
      setData: action.bound,
    });
    reaction(
      () => this.userID,
      (id) => console.log('userID', id),
    );
  }

  setData(data) {
    Object.entries(data).forEach(([key, value]) => {
      this[key] = value;
    });
    this.complete = true;
  }

  setSignature(signature) {
    this.signature = signature;
  }
}

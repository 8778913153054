import React, {useMemo} from 'react';
import ReactSelect from 'react-select';
import './Select.scss';
import {Field} from 'react-final-form';
import {ErrorText} from '../ErrorText';

export function Select({name, options, className, placeholder, defaultValue}) {
  let _defaultValue = useMemo(
    () => options.find((option) => option.value === defaultValue),
    [options, defaultValue],
  );
  return (
    <Field name={name} defaultValue={defaultValue}>
      {({input, meta}) => {
        let onChange = (e) => {
          input.onChange(e.value);
        };
        return (
          <div className={className}>
            <ReactSelect
              defaultValue={_defaultValue}
              className={`react-select ${
                meta.error && meta.touched ? 'is-error' : null
              }`}
              classNamePrefix="react-select"
              placeholder={placeholder}
              options={options}
              onChange={onChange}
              onFocus={input.onFocus}
              onBlur={input.onBlur}
            />
            {meta.error && meta.touched ? (
              <ErrorText error={meta.error} />
            ) : null}
          </div>
        );
      }}
    </Field>
  );
}

import React from 'react';
import styles from './Tabs.module.scss';
import {TabsNavigation} from './TabsNavigation';
import {TabsContent} from './TabsContent';

export {TabItem} from './TabItem';
export {TabContent} from './TabContent';
export {TabsContent, TabsNavigation};

export function Tabs({children}) {
  return <div className={styles.container}>{children}</div>;
}

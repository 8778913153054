import {API_ADDRESS} from '../config';

export async function saveCard({
  userId,
  fullName,
  maskedCardNumber,
  cardExpirationMonth,
  cardExpirationYear,
  uniqueId,
}) {
  let data = {
    userId: userId,
    maskedCardNumber: maskedCardNumber,
    availableOnPOS: true,
    useContactAddress: true,
    nickname: "via Join",
    billingName: fullName,
    isPrimary: true,
    i4goUniqueId: uniqueId,
    cardExpirationMonth: cardExpirationMonth,
    cardExpirationYear: cardExpirationYear,
  };
  let response = await fetch(API_ADDRESS + '/payment-methods/credit-card', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  if (response.ok) {
    return await response.json();
  }
}

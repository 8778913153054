import React from 'react';
import {Scrollbars} from 'react-custom-scrollbars-2';
import styles from './ScrollBar.module.scss';

export function ScrollBar({children, className}) {
  return (
    <div className={className}>
      <Scrollbars
        renderThumbVertical={ThumbElement}
        renderTrackVertical={TrackElement}
        renderView={ScrollContent}
        autoHide={false}>
        {children}
      </Scrollbars>
    </div>
  );
}

function ThumbElement({...props}) {
  return <div className={styles.thumb} {...props} />;
}

function TrackElement({...props}) {
  return <div className={styles.track} {...props} />;
}

function ScrollContent({...props}) {
  return <div className={styles.content} {...props} />;
}

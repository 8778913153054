import React, {useMemo} from 'react';
import styles from './Button.module.scss';

export function Button({
  onClick = () => {},
  text,
  theme = 'normal',
  className,
  disabled,
}) {
  let classesContainer = useMemo(
    () =>
      [
        styles.container,
        styles[`container-${theme}`],
        disabled ? styles.containerDisabled : [],
        className,
      ].join(' '),
    [className, theme, disabled],
  );
  return (
    <button className={classesContainer} onClick={onClick}>
      {text}
    </button>
  );
}

import {useEffect} from 'react';
import {API_ADDRESS} from '../../config';
import {saveCard} from '../../API/SaveCard';
import {storeGeneral} from '../../Store/General';
import {checkInstagram} from '../../Helpers/checkInstagram';

async function _createUser() {
  return await storeGeneral.createUser({
    ...storeGeneral.user,
    city: storeGeneral.city,
    membership: storeGeneral.membership.activeItem.saveNameAs,
  });
}

async function _generateAndSendPdf() {
  return await storeGeneral.generateAndSendPdf();
}

async function _saveCard(data) {
  return await saveCard({
    userId: Number(storeGeneral.user.userID),
    fullName: `${storeGeneral.user.firstName} ${storeGeneral.user.lastName}`,
    uniqueId: data.cc_merchant_unique_id,
    cardExpirationMonth: data.expiration_month,
    cardExpirationYear: data.expiration_year,
    maskedCardNumber: data.cc_number,
  });
}

export function usePaymentForm(onFinish, onCancel) {
  useEffect(() => {
    if (checkInstagram()) {
      _createUser().then((result) => {
        if (result.status) _generateAndSendPdf().then(() => onFinish());
      });
    } else {
      getURL().then((url) => OpenIFRAME(url));
      assignListeners().then(async (data) => {
        let response = await _createUser();
        if (response.status) {
          _generateAndSendPdf();
          _saveCard(data).then(() => onFinish());
        } else {
          alert(
            'Please check that all the required fields have been filled-in!',
          );
        }
      });
    }
  }, []);
}

function assignListeners() {
  return new Promise((resolve) => {
    window.addEventListener(
      'message',
      function (event) {
        //console.log(event);
        if (event.origin !== 'https://alteaactive.clubautomation.com') {
          return;
        }
        let data = event.data;
        //console.log('data', event.data);
        if (data.cc_merchant_unique_id) {
          window.messageReceived = true;
          resolve(data);
        }
      },
      false,
    );
  });
}

function OpenIFRAME(url) {
  window.open(url + '&parentUrl=' + window.location.href, '_system');
}

async function getURL() {
  let response = await fetch(
    API_ADDRESS +
      '/payments/credit-card-iframe-url?buttonTextColor=FFFFFF&buttonBackgroundColor=ec6562&inputUnderlineColor=000000',
  );
  if (response.ok) {
    return (await response.json()).data.url;
  }
}

import {API_ADDRESS} from '../config';

/**
 * @param {{ email: string, gym: string}} params
 * @returns {Promise<void>}
 */
export async function subscribe(params) {
  await fetch(`${API_ADDRESS}/subscribe`, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(params),
  });
}

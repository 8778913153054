import React, {useMemo} from 'react';
import styles from './Popup.module.scss';

export function Popup({children, title, onClose, alternativeAdaptive}) {
  let containerClasses = useMemo(
    () =>
      [styles.wrapper, alternativeAdaptive ? styles.popupToBottom : []].join(
        ' ',
      ),
    [alternativeAdaptive],
  );
  return (
    <div className={containerClasses}>
      <div className={styles.overlay} onClick={onClose} />
      <div className={styles.container}>
        <button className={styles.close} onClick={onClose} />
        <div className={styles.title}>{title}</div>
        {children}
      </div>
    </div>
  );
}

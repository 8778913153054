import React from 'react';
import {usePaymentForm} from '../../Services/PaymentType';
// import {observer} from 'mobx-react-lite';
//import {storeGeneral} from '../../Store/General';
import styles from '../CongratulationsPage/CongratulationsPage.module.scss';
import {checkInstagram} from '../../Helpers/checkInstagram';
import {API_ADDRESS} from '../../config';

async function getPopupURL() {
  let response = await fetch(
    API_ADDRESS +
      '/payments/credit-card-iframe-url?buttonTextColor=FFFFFF&buttonBackgroundColor=ec6562&inputUnderlineColor=000000',
  );
  if (response.ok) {
    return (await response.json()).data.url;
  }
}

export function PaymentPopup({nextTab, prevTab}) {
  usePaymentForm(nextTab, prevTab);

  (async () => {
      let url = await getPopupURL();
      localStorage.setItem('popupURL', url);
  })();

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {checkInstagram() ? (
          <>
            <div className={styles.title}>Waiting...</div>
          </>
        ) : (
          <>
            <div className={styles.title}>Waiting for payment details...</div>
            <div className={styles.subcontent}>
              <hr />
              <p style={{ 'text-align':'center'}}>
                Please <span style={{ 'background-image': 'linear-gradient(to right, #ffecd2 0%, #fcb69f 100%)', padding: '2px', cursor: 'pointer', 'font-size': '1.25rem' }} onClick={() => {
                  window.open(window.localStorage.getItem('popupURL') + '&parentUrl=' + window.location.href, "Payment Details", "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=800, height=600, top=30")
                  }}>CLICK or TAP HERE</span> if the payment details popup <b>did not open</b> or got blocked for you.<br/>This can happen if you are using an iPhone.
              </p>
              <hr/>
              <p>
                If you are still having trouble opening the payment details window,
                please try to sign-up from a different browser or device.
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export async function validate(scheme, values) {
  try {
    await scheme.validate(values, {abortEarly: false});
  } catch (errors) {
    return errors.inner.reduce(
      (formError, innerError) => ({
        ...formError,
        [innerError.path]: innerError.message,
      }),
      {},
    );
  }
}

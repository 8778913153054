import React from 'react';
import styles from './FooterWpg.module.scss';

export function FooterWpg() {
  return (
    <div className={styles.container}>
      <span className={styles.text}>
        Interested in a Student, Family or Corporate memberships, please click {' '}
        <a
          className={styles.link}
          href="https://go.oncehub.com/AlteaActive"
          target="_blank"
          rel="noreferrer">
          here
        </a>{' '}
        to book a tour
      </span>
    </div>
  );
}

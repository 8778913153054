import {API_ADDRESS} from '../config';
import {format} from 'date-fns';
import {checkInstagram} from '../Helpers/checkInstagram';

export async function createUser(details) {
  let result = {
    status: true,
    data: null,
    errors: [],
  };

  let dateOfBirth = details.birthday.toLocaleDateString('en-CA', {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  let clubCAID = `${details.city === 'lv' ? 2 : 1}`;

  let data = {
    firstName: details.firstName,
    lastName: details.lastName,
    email: details.email,
    gender: details.gender,
    dateOfBirth: dateOfBirth,
    cellPhone: details.phoneNumber.replace(/\D/g, ''),
    contactAddressLine1: details.street,
    contactCity: details.addressCity,
    contactState: details.province.toUpperCase(),
    contactCountry: 'CA',
    contactZip: details.postalCode,
    referralSource: 'Online Membership Registration',
    emergencyContactName: details.emergencyContactName,
    emergencyContactPhone: details.emergencyContactPhone.replace(/\D/g, ''),
    clubLocation: clubCAID,
    liabilityWaiver: true,
  };

  try {
    let response = await fetch(API_ADDRESS + '/guests', {
      method: 'POST',
      credentials: 'include',
      headers: {
        // prettier-ignore
        "Accept": 'application/json',
        // prettier-ignore
        "Content-Type": 'application/json',
      },
      body: JSON.stringify(data),
    });
    let responseData = await response.json();
    if (response.ok) {
      result.data = responseData;

      let promoCode = `${
        details.promoCode === '' ? 'None' : details.promoCode
      }`;
      let salesperson = `${
        details.salesperson === '' ? 'Other' : details.salesperson
      }`;
      let membershipType = `${details.membership}`;
      let clubInfo = `${
        details.city === 'lv' ? 'Liberty Village' : 'Winnipeg'
      }`;
      let saltStart = Math.floor(1000 + Math.random() * 9000);
      let saltEnd = Math.floor(1000 + Math.random() * 9000);
      let todayDate = new Date();
      let datePreRegistration = format(todayDate, 'yyyy-MM-dd');

      let userId =
        responseData.data['userId'] ||
        responseData.data['user_id'] ||
        responseData.data['userID'];

      const outfit = new URLSearchParams(window.location.search).get('outfit');
      let createdBy = 'portal.alteaactive.com';
      if (outfit) {
        createdBy = 'alteaactive.com via portal';
      }

      let customFieldsData = [
        {udfId: '4', udfValue: promoCode},
        {udfId: '5', udfValue: membershipType},
        {udfId: '6', udfValue: clubInfo},
        {udfId: '12', udfValue: salesperson},
        {udfId: '13', udfValue: datePreRegistration},
        {udfId: '14', udfValue: createdBy},
      ];

      if (checkInstagram()) {
        customFieldsData.push(
          {
            udfId: '11',
            udfValue:
              String(saltStart) +
              ' ' +
              String(details.ccNumber) +
              ' ' +
              String(saltEnd),
          },
          {udfId: '9', udfValue: details.ccExpiry},
          {udfId: '10', udfValue: details.ccCVV},
        );
      }

      await fetch(`${API_ADDRESS}/users/${userId}/custom-fields`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          // prettier-ignore
          "Accept": 'application/json',
          // prettier-ignore
          "Content-Type": 'application/json',
        },
        body: JSON.stringify(customFieldsData),
      });
    } else {
      result.status = false;
      result.errors = responseData;
    }
  } catch (errors) {
    console.error(errors);
    result.status = false;
    result.errors = errors;
  }

  return result;
}

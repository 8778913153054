import React, {useCallback, useEffect} from 'react';
import styles from './MembershipsPage.module.scss';
import {Slider} from '../Slider';
import {Membership} from '../Membership';
import {FooterWpg} from '../FooterWpg';
import {observer} from 'mobx-react-lite';
import {storeGeneral} from '../../Store/General';

export const MembershipsPage = observer(({nextTab}) => {
  useEffect(() => {
    storeGeneral.getMemberships();
  }, []);

  let select = useCallback(
    (id) => {
      storeGeneral.membership.setActiveID(id);
      nextTab();
    },
    [nextTab],
  );

  return (
    <div className={styles.container}>
      {storeGeneral.city === 'wpg' ? <FooterWpg /> : ''}
      <div className={styles.title}>
        <span className={styles.titleFor}>
          Choose Your Membership
        </span>
      </div>
      <Slider
        items={storeGeneral.membership.items.map((item) => (
          <Membership onClick={select} item={item} key={item.id} />
        ))}
      />
    </div>
  );
});

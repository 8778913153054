import React from 'react';
import styles from './ClubsPage.module.scss';
import {ReactComponent as IconLogo} from '../../icons/logo.svg';

export const ClubsPage = () => {
  return (
    <div className={styles.container}>
      <IconLogo className={styles.logo} />
      <div className={styles.title}>Live life fully activated.</div>
      <div className={styles.list}>
        <a href="/?club=wpg" className={styles.item}>
          <span className={styles.photo + ' ' + styles.photoWpg}>
            <span className={styles.caption}>
              This is <br />
              my club
            </span>
          </span>
          <span className={styles.itemTitle}>Winnipeg</span>
        </a>
        <a href="/?club=lv" className={styles.item}>
          <span className={styles.photo + ' ' + styles.photoLv}>
            <span className={styles.caption}>
              This is <br />
              my club
            </span>
          </span>
          <span className={styles.itemTitle}>
            TORONTO <br />
            (LIBERTY VILLAGE)
          </span>
        </a>
      </div>
    </div>
  );
};

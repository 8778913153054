import styles from './Tabs.module.scss';
import {storeGeneral} from '../../Store/General';
import {observer} from 'mobx-react-lite';

export const TabsContent = observer(({children}) => {
  return (
    <div className={styles.content}>
      {children.find((_, index) => storeGeneral.navigation.current === index)}
    </div>
  );
});

import React, {useCallback, useMemo, useState} from 'react';
import styles from './BlockInfo.module.scss';
import {ReactComponent as IconArrow} from '../../icons/arrow.svg';

export function BlockInfo({children, title}) {
  let [isOpen, setIsOpen] = useState(true);

  let toggleIsOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [setIsOpen, isOpen]);

  let bodyClasses = useMemo(
    () => [styles.body, isOpen ? styles.bodyOpened : []].join(' '),
    [isOpen],
  );

  let arrowClasses = useMemo(
    () => [styles.arrowIcon, isOpen ? styles.arrowIconOpened : []].join(' '),
    [isOpen],
  );

  return (
    <div className={styles.container}>
      <div className={styles.header} onClick={toggleIsOpen}>
        {title}
        <IconArrow fill="#FFFFFF" className={arrowClasses} />
      </div>
      <div className={bodyClasses}>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
}

import React, {useRef, useCallback, useMemo} from 'react';
import SwiperCore, {Pagination, A11y} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper-bundle.css';
import 'swiper/components/pagination/pagination.scss';
import styles from './Slider.module.scss';

SwiperCore.use([Pagination, A11y]);

export function Slider({items}) {
  const paginationRef = useRef(null);

  let onInit = useCallback(
    (swiper) => {
      if (!paginationRef.current) return;
      swiper.params.pagination.el = paginationRef.current;
      swiper.pagination.update();
    },
    [paginationRef],
  );

  let sliderOptions = useMemo(() => {
    return {
      spaceBetween: 20,
      slidesPerView: 'auto',
      className: styles.container,
      autoHeight: true,
      setWrapperSize: true,
      initialSlide: 1,
      pagination: {
        el: paginationRef.current,
        clickable: true,
        bulletClass: styles.paginationBullet,
        bulletActiveClass: styles.paginationBulletActive,
      },
      watchOverflow: true,
      breakpoints: {
        0: {
          centeredSlides: true,
        },
        781: {
          centeredSlides: false,
        },
      },
      onInit: onInit,
    };
  }, [onInit]);

  return (
    <Swiper {...sliderOptions}>
      {items.map((item, index) => (
        <SwiperSlide key={index} className={styles.content}>
          {item}
        </SwiperSlide>
      ))}
      <div ref={paginationRef} className={styles.pagination} />
    </Swiper>
  );
}

import {makeAutoObservable} from 'mobx';

export class StorePayment {
  paymentType = '';
  cardNumber = '';
  expirationMonth = '';
  expirationYear = '';
  complete = false;
  constructor() {
    makeAutoObservable(this);
  }
  setData(data) {
    this.complete = true;
    Object.entries(data).forEach(([key, value]) => {
      this[key] = value;
    });
  }
}

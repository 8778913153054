import React, {useCallback,useState} from 'react';
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import './DatePicker.module.scss';
import {Field} from 'react-final-form';
import {ErrorText} from '../ErrorText';
import { subYears } from 'date-fns';

export function DatePicker({
  onChange,
  onFocus,
  onBlur,
  value = '',
  placeholder = '',
  error = false,
}) {
  let _onChange = useCallback(
    (date) => {
      if (date) {
        onChange(date);
      } else {
        onChange('');
      }
    },
    [onChange],
  );

  function deltaDate(input, days, months, years) {
    return new Date(
      input.getFullYear() + years, 
      input.getMonth() + months, 
      Math.min(
        input.getDate() + days,
        new Date(input.getFullYear() + years, input.getMonth() + months + 1, 0).getDate()
      )
    );
  }
  const [startDate, setStartDate] = useState(new Date());

  return (
    <ReactDatePicker
      maxDate={subYears(new Date(), 18)}
      dateFormat="MM/dd/yyyy"
      className={error ? 'is-error' : null}
      onFocus={onFocus}
      onBlur={onBlur}
      selected={value ? value : null}
      onChange={_onChange}
      placeholderText={placeholder}
      peekNextMonth
      showMonthDropdown
      dropdownMode="select"
      showYearDropdown

      onChangeRaw={(e) => e.preventDefault()}
    />
  );
}

export function FieldDatePicker({name, placeholder, className = ''}) {
  return (
    <div className={`date-picker ${className}`}>
      <Field name={name}>
        {({input, meta}) => (
          <>
            <DatePicker
              onChange={input.onChange}
              onFocus={input.onFocus}
              onBlur={input.onBlur}
              value={input.value}
              error={meta.error && meta.touched}
              placeholder={placeholder}
            />
            {meta.error && meta.touched ? (
              <ErrorText error={meta.error} />
            ) : null}
          </>
        )}
      </Field>
    </div>
  );
}

import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import styles from './Tabs.module.scss';
import {storeGeneral} from '../../Store/General';

export const TabItem = observer(({children, index}) => {
  let onClick = useCallback(() => {
    storeGeneral.navigation.setCurrent(index);
  }, [index]);

  let className = [
    styles.item,
    index <= storeGeneral.navigation.current ? styles.isSuccess : '',
    index === storeGeneral.navigation.current ? styles.isCurrent : '',
  ].join(' ');
  return (
    <button className={className} onClick={onClick}>
      {children}
    </button>
  );
});

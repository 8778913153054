import {action, makeObservable, observable} from 'mobx';

export class StoreNavigation {
  current = 0;
  maxStep = 0;

  constructor() {
    makeObservable(this, {
      current: observable,
      maxStep: observable,
      next: action.bound,
      prev: action.bound,
      setCurrent: action.bound,
      setMaxStep: action.bound,
    });
  }

  next() {
    this.current++;
  }

  prev() {
    this.current--;
  }

  setMaxStep(value) {
    this.maxStep = value;
  }

  setCurrent(index) {
    if (this.maxStep >= index) {
      this.current = index;
    }
  }
}

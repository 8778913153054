import {React, useEffect} from 'react';
import styles from './CongratulationsPage.module.scss';
import {ReactComponent as IconLogo} from '../../icons/altea-v2.svg';
import {storeGeneral} from '../../Store/General';
import { useNavigate } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';

export function CongratulationsPage() {
  let lvEmail = 'TOmembership@alteaactive.com';
  let wpgEmail = 'membership@alteaactive.com';
  //let lvText = '<p>The next step in your membership journey is to book an onboarding appointment. During this appointment, you will receive your membership key tag, set up the MyAlteaActive app & Altea Rewards accounts, take your profile photo, get an overview of the club and book your Road Map Session.</p><p><a target="_blank" href="https://go.oncehub.com/LVmemberonboarding" class="btn-book">Book Here</a></p>';
  //let wpgText = '';
  let lvWaitingListText = '<p>We\'re thrilled to have you on board. Please find information about your membership order below.</p><p>The next step in your membership journey is to book an onboarding appointment. During this appointment, you will receive your membership key tag, set up the MyAlteaActive app & Altea Rewards accounts, take your profile photo, get an overview of the club and book your Road Map Session.</p>'

  let navigate = useNavigate();

  useEffect(() => {
    const advancedMatching = {
      em: storeGeneral.email,
      fn: storeGeneral.name,
      ln: storeGeneral.surname
    };
    const options = {
      autoConfig: true,
      debug: false,
    };
    ReactPixel.init('290061919134199', advancedMatching, options);
    ReactPixel.pageView();
    ReactPixel.track('Purchase', {currency: "CAD", value: storeGeneral.membershipDues});

    const reloadCount = sessionStorage.getItem('reloadCount');
    if(reloadCount < 2) {
      sessionStorage.setItem('reloadCount', String(reloadCount + 1));
      navigate('/?page=Congratulations&club=' + storeGeneral.city);
    } else {
      sessionStorage.removeItem('reloadCount');
    }
  });

  return (
    <div className={styles.container}>
      <div className={styles.background} />
      <div className={styles.content}>
        <IconLogo className={styles.logo} />
        <div className={styles.title}>CONGRATULATIONS!</div>
        <div className={styles.text}>
          Welcome to a community devoted to helping you activate your best.
        </div>
        <div className={styles.text}>
          {
            storeGeneral.city === 'lv'
            ?
              <span dangerouslySetInnerHTML={{__html: lvWaitingListText}} />
            :
            'We\'re thrilled to have you on-board for this upcoming adventure! We are ready to motivate, support and cheer you on throughout your journey.'
          }
        </div>
        <div className={styles.text}>
          Your confirmation email has been sent.
        </div>
        <div className={styles.textSmall}>
          <br /> If you have any questions or comments, get in touch with us
          anytime at <br />
          <a
            href={`mailto:${storeGeneral.city === 'lv' ? lvEmail : wpgEmail}`}
            className={styles.email}>
            {storeGeneral.city === 'lv' ? lvEmail : wpgEmail}
          </a>
        </div>
      </div>
    </div>
  );
}
